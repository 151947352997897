.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* JODIT WYSIWYG Editor CSS */
.jodit-checkbox {
  border: #a7a7a7 1px solid !important;
  margin-right: 0.5rem !important;
}

.jodit-ui-button_ok {
  background-color: #ff7295 !important;
}

.jodit-ui-button_ok:hover {
  background-color: #ff4977 !important;
}

.jodit-ui-button_keep {
  background-color: #ff7295 !important;
}

.jodit-ui-button_keep:hover {
  background-color: #ff4977 !important;
}

.jodit-wysiwyg a {
  text-decoration: underline !important;
  color: blue !important; 
}

.jodit-wysiwyg ul {
  padding: 0.5rem 0 0.5rem 1.25rem !important;
  list-style-type: disc !important;
}

.jodit-wysiwyg ol {
  padding: 0.5rem 0 0.5rem 1.25rem !important;
  list-style-type: decimal !important;
}

.jodit-wysiwyg ul li {
  /* list-style-type: disc !important; */
}

/* TinyMCE */
.tox-statusbar__path {
  max-width: 100%;
  overflow: scroll;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tox-statusbar__branding {
  display: none;
}


/* Scrollbar CSS */
/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0,0,0,0.1);
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,0.2);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0,0,0,0.4);
}

/* Handle on click and hold */
::-webkit-scrollbar-thumb:active{
  background: rgba(0,0,0,.7);
}
